import styled from 'styled-components';
import variables from '../../../data/variables';

export const MainContent = styled.div`
  @media(min-width: ${variables.breakpointPhone}) {
    background-color: white;
    height: 100vh;

  }
  @media(min-width: ${variables.breakpointPhone}) {
		background-color: white;
	}
`
